<template src='./HorizontalCardComingSoon.html'></template>

<script>
export default {
  name: "horizontal-card-comingSoon",
  props: {
    message: { type: String },
  },
};
</script>

<style lang="scss" scoped src="./HorizontalCardComingSoon.scss"></style>