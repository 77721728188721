<template src='./AnnouncementOpen.html'></template>

<script>
export default {
  name: "announcement-open",
  props: {
    id: { type: String },
    name: { type: String },
    dateInit: { type: String },
    dateFinish: { type: String },
    postulates: { type: String, default: "0" },
    image: { type: String },
    descriptionImg: { type: String, default: "Imagen de convocatoria abierta" },
    buttonText: { type: String },
  },
  data() {
    return {
      disableButton: "",
      role: "",
    };
  },
  created() {
    this.role = this.$store.state.User.user.role.name;
  },
  computed: {
    imgUrl() {
      return this.image
        ? this.image
        : "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela.appspot.com/o/Img_default.png?alt=media&token=bee72947-8de9-498d-b907-0043331f997a";
    },
    roleValidationAdminAnnouncement() {
      return ["AdministratorCall", "Administrator"].some(
        (a) => a === this.role
      );
    },
    roleValidation() {
      return ["Student", "Teacher", "Entrepreneur"].some(
        (a) => a === this.role
      );
    },
    roleValidationTeacherInvestigator() {
      return ["TeacherInvestigator"].some((a) => a === this.role);
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./AnnouncementOpen.scss"></style>